/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, ImgHTMLAttributes } from 'react';

export interface ResponsiveImageProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  baseName:
    | 'about'
    | 'discover'
    | 'how-it-works-1'
    | 'how-it-works-2'
    | 'how-it-works-3'
    | 'why-choose-mailnum'
    | 'people'
    | 'join';
  ext?: string;
}

const ResponsiveImage: FC<ResponsiveImageProps> = ({
  baseName,
  ext = 'png',
  ...other
}) => {
  const src1x = require(`assets/images/${baseName}/${baseName}.${ext}`);
  const src2x = require(`assets/images/${baseName}/${baseName}@2x.${ext}`);
  const src3x = require(`assets/images/${baseName}/${baseName}@3x.${ext}`);

  return (
    <img
      src={src1x}
      srcSet={`${src1x} 1x, ${src2x} 2x, ${src3x} 3x`}
      loading="lazy"
      {...other}
    />
  );
};

export default ResponsiveImage;
