import ArrowPathIcon from '@heroicons/react/24/solid/ArrowPathIcon';
import { motion } from 'framer-motion';
import React from 'react';

const CircularIndicator = () => (
  <motion.div
    animate={{ rotate: 360 }}
    transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <ArrowPathIcon className="size-5" />
  </motion.div>
);

export default CircularIndicator;
