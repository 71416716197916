import React, { FC, Fragment } from 'react';

import { Content, Footer, Header } from './components';

const App: FC = () => {
  return (
    <Fragment>
      <Header />
      <Content />
      <Footer />
    </Fragment>
  );
};

export default App;
