import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import ChevronDownIcon from '@heroicons/react/16/solid/ChevronDownIcon';
import { clsx } from 'clsx';
import React from 'react';

import { Wrapper } from '../../common';

const _sectons = [
  {
    title: 'How secure is MailNumber?',
    subtitle:
      'MailNumber uses SMS-based two-factor authentication, ensuring only you can access your email.',
  },
  {
    title: 'How do I claim my MailNumber email address?',
    subtitle:
      'MailNumber uses SMS-based two-factor authentication, ensuring only you can access your email.',
  },
  {
    title: 'Can I change my forwarding email address?',
    subtitle:
      'MailNumber uses SMS-based two-factor authentication, ensuring only you can access your email.',
  },
  {
    title: "What happens if I don't set up a forwarding email right away?",
    subtitle:
      'MailNumber uses SMS-based two-factor authentication, ensuring only you can access your email.',
  },
];

const FaqSection = () => {
  return (
    <Wrapper internalClassName="flex flex-col items-center justify-between gap-8 text-center md:flex-row md:gap-20 md:text-left">
      <div className="flex flex-1 flex-col gap-6 md:items-start">
        <p className="caption tag">Got Questions?</p>
        <h1 className="responsive-heading-1">Frequently Asked Questions</h1>
        <p className="responsive-paragraph">
          At MailNumber, we understand that you may have a few questions before
          getting started. Our FAQ section covers the most common inquiries to
          help you get the most out of our service. If you don&apos;t find the
          answer you&apos;re looking for, feel free to reach out to our support
          team.
        </p>
      </div>
      <div className="flex w-full flex-1 flex-col gap-6">
        {_sectons.map(({ title, subtitle }) => (
          <Disclosure key={title}>
            {({ open }) => (
              <div className="border-b-[1px] border-gray-300 pb-6">
                <DisclosureButton className="flex w-full items-center justify-between gap-10 text-left">
                  <h4>{title}</h4>
                  <ChevronDownIcon
                    className={clsx('size-5 min-w-5', open && 'rotate-180')}
                  />
                </DisclosureButton>
                <DisclosurePanel
                  transition
                  className="pt-3 transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                >
                  <p className="caption text-left">{subtitle}</p>
                </DisclosurePanel>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
    </Wrapper>
  );
};

export default FaqSection;
