import { Field, Textarea } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import { InputProps } from './types';

export type TextAreaProps = InputProps &
  InputHTMLAttributes<HTMLTextAreaElement>;

// eslint-disable-next-line react/display-name
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, error, ...rest }, ref) => {
    return (
      <Field className={clsx('flex w-full flex-col', className)}>
        <Textarea
          ref={ref}
          {...rest}
          placeholder="Your Message"
          className={clsx(
            'w-full rounded-md border-[1px] border-slate-300 px-3 py-4 text-base placeholder-black',
            className
          )}
          rows={4}
        />
        {error && (
          <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
        )}
      </Field>
    );
  }
);

export default TextArea;
