import StarIcon from '@heroicons/react/16/solid/StarIcon';
import React from 'react';

import { ResponsiveImage, Wrapper } from '../../common';

const ReviewSection = () => {
  return (
    <Wrapper internalClassName="p-block bg-slate-100 rounded-3xl flex flex-col items-center gap-8 md:gap-14 text-center">
      <div className="flex flex-row flex-wrap items-center justify-center gap-2">
        <StarIcon className="size-11 text-orange-400" />
        <h1 className="mr-2 text-5xl">4.9</h1>
        <ResponsiveImage baseName="people" />
      </div>
      <h1 className="responsive-heading-1">
        “Mail Number has made managing my communications so much easier. The
        setup was quick, and I love how secure it feels!”
      </h1>
      <p className="responsive-paragraph">
        – John Anderson, Digital Marketing Specialist
      </p>
    </Wrapper>
  );
};

export default ReviewSection;
