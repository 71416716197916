import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { Wrapper } from 'core/components/common';

import ContactForm from './ContactForm';

const ContactSection = () => {
  return (
    <Wrapper internalClassName="mx-auto flex max-w-7xl flex-col items-center gap-10 md:flex-row">
      <div className="space-y-4 text-center md:w-1/2 md:text-left">
        <h2 className="responsive-heading-1">Get in Touch</h2>
        <div className="flex items-center justify-center gap-4 md:justify-start">
          <PhoneIcon className="size-6 fill-current text-pink-500" />
          <p className="text-gray-700">1-800-123-4567</p>
        </div>
        <div className="flex items-center justify-center gap-4 md:justify-start">
          <EnvelopeIcon className="size-6 text-pink-500" />
          <p className="text-gray-700">support@mailnum.com</p>
        </div>
      </div>

      <ContactForm />
    </Wrapper>
  );
};

export default ContactSection;
