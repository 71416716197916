import { Field, Input, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import { InputProps } from './types';

export type TextInputProps = InputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    renderIcon?: () => React.ReactNode;
  } & {
    type?:
      | 'email'
      | 'hidden'
      | 'number'
      | 'password'
      | 'search'
      | 'tel'
      | 'text'
      | 'url';
  };

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, error, className, renderIcon, ...rest }, ref) => {
    return (
      <Field className={clsx('flex w-full flex-col', className)}>
        {label && <Label className="caption mb-2 ml-1">{label}</Label>}
        <div className="relative">
          <Input
            ref={ref}
            {...rest}
            className={clsx(
              'w-full rounded-md border-[1px] border-slate-300 px-3 py-4 text-base placeholder-black',
              className
            )}
          />
          <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            {renderIcon && renderIcon()}
          </div>
        </div>
        {error && (
          <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
        )}
      </Field>
    );
  }
);

export default TextInput;
