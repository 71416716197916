import React from 'react';

import { ReactComponent as Spiral } from 'assets/svgs/spiral.svg';
import { APP_URL } from 'core/constants';

import { ResponsiveImage, Wrapper } from '../../common';

const JoinSection = () => {
  return (
    <Wrapper internalClassName="relative flex flex-col items-center gap-8 rounded-3xl bg-primary px-12 pt-12 text-center lg:flex-row lg:items-stretch lg:justify-between lg:gap-14 lg:px-24 lg:pt-24 lg:text-left xl:px-[108px] xl:pt-[120px]">
      <div className="flex max-h-[380px] max-w-lg flex-col items-center justify-between lg:items-start">
        <div>
          <h1 className="responsive-heading-1 text-white">
            Join the Future of Communication
          </h1>
          <p className="responsive-paragraph mt-3 font-light text-white">
            Get started with MailNumber today—secure, simple, and perfectly
            aligned with your communication needs.
          </p>
        </div>
        <a className="button mb-8 mt-3 bg-white text-primary" href={APP_URL}>
          Get yours FREE Now 🎯
        </a>
      </div>
      <ResponsiveImage
        baseName="join"
        className="z-10 max-w-72 sm:max-w-80 xl:max-w-96"
      />
      <Spiral className="absolute bottom-0 right-0 z-0 hidden md:block" />
    </Wrapper>
  );
};

export default JoinSection;
