import { Button } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FormTextArea, FormTextInput } from 'core/components/common/form';
import { CircularIndicator } from 'core/components/common/uikit';

const schema = yup
  .object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    message: yup.string().required('Your message is required'),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const defaultValues: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

const ContactForm = () => {
  const methods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  });

  const { control, handleSubmit, formState } = methods;

  const onSubmit = handleSubmit(async (data) => {
    // TODO: Implement logic
    console.log(data);
  });

  return (
    <form
      className="w-full space-y-4 rounded-[44px] bg-slate-100 p-11 md:w-1/2"
      onSubmit={onSubmit}
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <FormTextInput
          name="firstName"
          control={control}
          componentProps={{
            type: 'text',
            placeholder: 'First Name',
          }}
        />
        <FormTextInput
          name="lastName"
          control={control}
          componentProps={{
            type: 'text',
            placeholder: 'Last Name',
          }}
        />
      </div>
      <FormTextInput
        name="email"
        control={control}
        componentProps={{
          type: 'email',
          placeholder: 'Email',
        }}
      />
      <FormTextArea
        name="message"
        control={control}
        componentProps={{
          type: 'text',
          placeholder: 'Your message',
        }}
      />
      <Button type="submit" className="button w-full">
        {formState.isSubmitting ? <CircularIndicator /> : 'Send'}
      </Button>
    </form>
  );
};

export default ContactForm;
