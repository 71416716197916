import React from 'react';

import { ReactComponent as Logo } from 'assets/svgs/logo.svg';

const AppLogo = () => {
  return (
    <a href="#">
      <Logo className="w-52" />
    </a>
  );
};

export default AppLogo;
