import { ArrowRightIcon } from '@heroicons/react/20/solid';
import React from 'react';

import TextInput from 'core/components/common/uikit/TextInput';
import { APP_URL } from 'core/constants';

import { ResponsiveImage, Wrapper } from '../../common';

const WelcomeSection = () => {
  return (
    <Wrapper internalClassName="flex flex-col items-center justify-between gap-8 text-center md:flex-row md:gap-20 md:text-left">
      <div className="flex flex-1 flex-col gap-5 md:items-start">
        <h1 className="responsive-heading-1">
          Your Phone Number is your Email Address
        </h1>
        <p className="responsive-paragraph">
          It&apos;s the perfect blend of simplicity and security, designed for
          seamless communication in today&apos;s fast-paced world.
        </p>
        <div className="mb-4 mt-11 w-full rounded-xl border border-slate-100 bg-white px-8 py-4 md:px-6 md:py-6 lg:w-[90%] lg:rounded-3xl lg:px-11 xl:w-[80%]">
          <TextInput
            className="rounded-md border-none bg-slate-100 text-sm lg:text-base"
            name="phone"
            disabled
            placeholder="4967327327@mailnum.com"
            renderIcon={() => (
              <ArrowRightIcon className="size-4 text-black md:size-5" />
            )}
          />
        </div>

        <a className="button" href={APP_URL}>
          Get yours FREE Now 📧
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        <ResponsiveImage baseName="about" />
      </div>
    </Wrapper>
  );
};

export default WelcomeSection;
