import React from 'react';

import ContactSection from './components/ContactSection';
import FaqSection from './components/FaqSection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import JoinSection from './components/JoinSection';
import PricingSection from './components/PricingSection';
import ReviewSection from './components/ReviewSection';
import WelcomeSection from './components/WelcomeSection';
import WhatIsSection from './components/WhatIsSection/WhatIsSection';

const Content = () => {
  return (
    <main className="flex-grow py-6 md:py-10 lg:py-16">
      <WelcomeSection />
      <WhatIsSection />
      <HowItWorksSection />
      <FeaturesSection />
      <PricingSection />
      <ReviewSection />
      <FaqSection />
      <JoinSection />
      <ContactSection />
    </main>
  );
};

export default Content;
