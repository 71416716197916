import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { FC, ReactElement } from 'react';

import { DialogProps } from './types';

const CountriesDialog: FC<DialogProps> = ({
  isOpen,
  onClose,
  list,
}): ReactElement => {
  return (
    <Dialog open={isOpen} as="div" onClose={onClose}>
      <div className="fixed inset-0 m-auto h-[90vh] w-[90vw] rounded-3xl bg-white px-8 py-14 shadow-lg">
        <div className="h-full overflow-y-auto px-2">
          <div className="flex flex-wrap justify-start gap-y-8 md:gap-4 lg:gap-6 xl:gap-8">
            {Object.entries(list).map(([region, countries]) => (
              <div key={region} className="min-w-[200px] space-y-2">
                <h5>{region}</h5>
                <ul className="caption-thin list-inside list-disc space-y-2">
                  {countries.map((country) => (
                    <li key={country}>{country}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="absolute right-2 top-2 rounded-full border p-2">
          <XMarkIcon className="h-5 w-5 cursor-pointer" onClick={onClose} />
        </div>
      </div>
    </Dialog>
  );
};

export default CountriesDialog;
