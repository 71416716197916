import ArrowUpIcon from '@heroicons/react/24/solid/ArrowUpIcon';
import React from 'react';

import { ReactComponent as FacebookLogo } from 'assets/svgs/facebook.svg';
import { ReactComponent as InstagramLogo } from 'assets/svgs/instagram.svg';
import { ReactComponent as LinkedinLogo } from 'assets/svgs/linkedin.svg';
import { ReactComponent as TwitterLogo } from 'assets/svgs/twitter.svg';

import { Wrapper } from './common';

const Footer = () => {
  return (
    <Wrapper
      as="footer"
      id="footer"
      className="border-t-[1px] border-gray-300 bg-slate-100 !pb-16 !pt-32"
      internalClassName=""
    >
      <div className="flex flex-col items-center gap-6 md:flex-row md:items-start md:justify-between md:gap-8">
        <div className="flex flex-col items-center gap-3 md:items-start md:gap-8">
          <p className="caption font-light">MENU</p>
          <div className="flex flex-row flex-wrap justify-center gap-2 md:justify-start">
            <a href="#" className="heading-3 font-light">
              Home
            </a>
            <p className="heading-2 font-thin">/</p>
            <a href="#how-it-works" className="heading-3 font-light">
              How it Works
            </a>
            <p className="heading-2 font-thin">/</p>
            <a href="#features" className="heading-3 font-light">
              Features
            </a>
            <p className="heading-2 font-thin">/</p>
            <a href="#" className="heading-3 font-light">
              Pricing
            </a>
            <p className="heading-2 font-thin">/</p>
            <a href="#footer" className="heading-3 font-light">
              Contact
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center gap-3 text-center md:items-start md:gap-8 md:text-left">
          <p className="caption font-light">CONTACT US</p>
          <div>
            <p className="caption">1-800-123-4567</p>
            <p className="caption">support@mailnum.com</p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-3 md:items-start md:gap-8">
          <p className="caption font-light">FOLLOW US</p>
          <div className="flex flex-row flex-wrap justify-center gap-2 md:justify-start">
            <a href="#">
              <InstagramLogo />
            </a>
            <a href="#">
              <FacebookLogo />
            </a>
            <a href="#">
              <TwitterLogo />
            </a>
            <a href="#">
              <LinkedinLogo />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center justify-between gap-8 md:mt-40 md:flex-row">
        <div className="flex flex-col items-center gap-2 md:flex-row md:gap-6">
          <a href="#" className="caption">
            Privacy Policy
          </a>
          <a href="#" className="caption">
            Terms of Service
          </a>
        </div>
        <a href="#">
          <ArrowUpIcon className="size-4 text-gray-800" />
        </a>
      </div>
    </Wrapper>
  );
};

export default Footer;
