import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { DefaultFormInputProps } from './types';
import { TextArea, TextAreaProps } from '../uikit';

const FormTextArea = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, TextAreaProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  return <TextArea {...field} error={error} {...componentProps} />;
};

export default FormTextArea;
