export const countriesByRegion: Record<string, string[]> = {
  Africa: [
    'Kenya',
    'Nigeria',
    'South Africa',
    'Uganda',
    'Tanzania',
    'Ghana',
    'Zimbabwe',
    'Botswana',
    'Zambia',
    'Ivory Coast',
  ],
  Asia: [
    'India',
    'China',
    'Japan',
    'South Korea',
    'Singapore',
    'Hong Kong',
    'Thailand',
    'Philippines',
    'Malaysia',
    'Taiwan',
    'Vietnam',
    'Indonesia',
    'Pakistan',
    'Bangladesh',
    'Sri Lanka',
    'Nepal',
  ],
  Europe: [
    'United Kingdom',
    'Germany',
    'France',
    'Italy',
    'Spain',
    'Netherlands',
    'Sweden',
    'Belgium',
    'Switzerland',
    'Austria',
    'Denmark',
    'Norway',
    'Finland',
    'Ireland',
    'Poland',
    'Czech Republic',
    'Portugal',
    'Romania',
    'Slovakia',
    'Hungary',
    'Bulgaria',
    'Ukraine',
    'Greece',
  ],
  'North America': ['United States', 'Canada', 'Mexico'],
  'South America': [
    'Brazil',
    'Argentina',
    'Chile',
    'Colombia',
    'Peru',
    'Ecuador',
    'Paraguay',
    'Bolivia',
    'Uruguay',
  ],
  Oceania: ['Australia', 'New Zealand'],
  'Middle East': [
    'United Arab Emirates',
    'Saudi Arabia',
    'Israel',
    'Turkey',
    'Lebanon',
    'Kuwait',
    'Qatar',
    'Jordan',
    'Bahrain',
    'Oman',
  ],
  Caribbean: [
    'Bahamas',
    'Barbados',
    'Dominican Republic',
    'Jamaica',
    'Trinidad & Tobago',
    'Cuba',
    'Saint Lucia',
  ],
};
