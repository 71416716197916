import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';

import { Wrapper } from 'core/components/common';
import { APP_URL } from 'core/constants';

const PricingSection = () => {
  return (
    <Wrapper internalClassName=" flex flex-col items-center text-center gap-8 md:gap-12">
      <p className="caption tag">Pricing</p>
      <div className="flex flex-col gap-8 md:max-w-[60%] md:gap-12 xl:max-w-[55%]">
        <h1 className="responsive-heading-1">
          Choose the Plan That Fits Your Needs
        </h1>
        <p className="responsive-paragraph">
          MailNumber is designed to make communication simple, secure, and
          accessible. Whether you&apos;re looking to streamline your messages
          with a basic plan or need advanced features for added flexibility, we
          have options tailored for you
        </p>
      </div>
      <div className="flex w-full flex-col gap-6 lg:flex-row">
        {/* Left card */}
        <div className="flex w-full flex-col gap-4 rounded-3xl bg-slate-100 p-6 text-left">
          <h4>Basic plan</h4>
          <p className="caption text-left">
            Get a MailNumber email address and start receiving messages with our
            standard features.
          </p>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">Email forwarding to one address</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">SMS forwarding to your inbox</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">Spam filtering</p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <h3 className="responsive-heading-3">Free</h3>
            <a href={APP_URL} className="button">
              Get started free
            </a>
          </div>
        </div>

        {/* Right card */}
        <div className="flex w-full flex-col gap-4 rounded-3xl bg-slate-100 p-6 text-left">
          <h4>Premium Plan</h4>
          <p className="caption text-left">
            Unlock the full potential of MailNumber with our Premium Plan,
            featuring advanced tools for power users.
          </p>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">Additional phone number</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">Forwarding to up to 5 emails</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <div className="flex items-center justify-center rounded-full bg-appLightBlue p-1 text-center text-primary">
              <CheckIcon className="size-3" />
            </div>
            <p className="caption-thin">
              Priority support & enhanced message filtering
            </p>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-baseline">
              <span className="responsive-heading-4 font-bold">$</span>
              <span className="responsive-heading-3">5</span>
              <span className="caption-thin pl-1">/month</span>
            </div>
            <a href={APP_URL} className="button">
              Upgrade to Premium
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PricingSection;
