import React from 'react';

import { APP_URL } from 'core/constants';

import { ResponsiveImage, Wrapper } from '../../common';

const HowItWorksSection = () => {
  return (
    <Wrapper
      id="how-it-works"
      internalClassName="p-block bg-slate-100 rounded-3xl flex flex-col items-center gap-8 md:gap-12 text-center"
    >
      <p className="caption tag">How It Works 🛠️</p>
      <h1 className="responsive-heading-1">
        From Phone Number to Email in 3 Simple Steps
      </h1>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="flex flex-col rounded-2xl bg-white p-6 text-left">
          <ResponsiveImage baseName="how-it-works-1" />
          <h3 className="responsive-heading-3 mt-10">
            Enter your phone number. 📲
          </h3>
          <p className="caption mt-2">
            Enter your phone number to begin setting up your new email address.
          </p>
        </div>
        <div className="flex flex-col rounded-2xl bg-white p-6 text-left">
          <ResponsiveImage baseName="how-it-works-2" />
          <h3 className="responsive-heading-3 mt-10">
            Verify with a quick SMS. ✔️
          </h3>
          <p className="caption mt-2">
            Receive a verification code via SMS to secure your new email
            address.
          </p>
        </div>
        <div className="flex flex-col rounded-2xl bg-white p-6 text-left">
          <ResponsiveImage baseName="how-it-works-3" />
          <h3 className="responsive-heading-3 mt-10">
            Set your forwarding email. ✉️
          </h3>
          <p className="caption mt-2">
            Choose where you want your emails to be delivered, and you&apos;re
            all set!
          </p>
        </div>
      </div>
      <p className="responsive-paragraph">
        That&apos;s it! You&apos;re ready to manage all your communications from
        your preferred inbox
      </p>
      <a className="button" href={APP_URL}>
        Get yours FREE Now ⚡
      </a>
    </Wrapper>
  );
};

export default HowItWorksSection;
