import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { DOMAttributes, FC, Fragment } from 'react';

import { APP_URL } from 'core/constants';

type BurgerMenuButtonProps = {
  opened: boolean;
  onClick: DOMAttributes<HTMLButtonElement>['onClick'];
};

export const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({
  opened,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
    >
      <span className="sr-only">{opened ? 'Open menu' : 'Close menu'}</span>
      {opened ? (
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      ) : (
        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
      )}
    </button>
  );
};

export const HeaderLinks = () => {
  return (
    <Fragment>
      <a href="#how-it-works" className="caption">
        How it Works
      </a>
      <a href="#features" className="caption">
        Features
      </a>
      <a href="#" className="caption">
        Pricing
      </a>
      <a href="#footer" className="caption">
        Contact
      </a>
    </Fragment>
  );
};

export const HeaderButtons = () => {
  return (
    <Fragment>
      <a href={APP_URL} className="caption text-gray-800">
        Login
      </a>
      <a href={APP_URL} className="button">
        Sign Up
      </a>
    </Fragment>
  );
};
