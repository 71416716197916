import React, { useState } from 'react';

import { countriesByRegion } from './countries';
import CountriesDialog from './CountriesDialog';
import { ResponsiveImage, Wrapper } from '../../../common';

const WhatIsSection = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDialog = () => setIsOpen(!isOpen);

  return (
    <>
      <Wrapper internalClassName="flex flex-col-reverse items-center justify-between gap-8 text-center md:flex-row md:gap-20 md:text-left">
        <div className="flex flex-1 justify-end">
          <ResponsiveImage baseName="discover" />
        </div>
        <div className="flex flex-1 flex-col gap-5 md:items-start">
          <p className="caption tag">Discover the Service 🔍</p>
          <h1 className="responsive-heading-1">What is Mail Number?</h1>
          <p className="responsive-paragraph">
            MailNumber is an innovative service that instantly assigns you a
            unique email address based on your phone number. No complicated
            setups or lengthy forms—just enter your phone number, verify via
            SMS, and start using your new email address immediately. It&apos;s
            that easy. 🎉
          </p>
          <p className="responsive-paragraph">
            And with a subscription, you can add more Mail Number emails as well
            as additional phone numbers.*
          </p>
          <div className="caption-thin font-normal">
            <span>*Phone numbers from over 150 countries supported.</span>
            <span
              className="cursor-pointer text-primary"
              onClick={toggleDialog}
            >
              {' '}
              Please check our list of supported countries here.
            </span>
          </div>
        </div>
      </Wrapper>

      <CountriesDialog
        isOpen={isOpen}
        onClose={toggleDialog}
        list={countriesByRegion}
      />
    </>
  );
};

export default WhatIsSection;
