import CheckIcon from '@heroicons/react/16/solid/CheckIcon';
import React from 'react';

import { ResponsiveImage, Wrapper } from '../../common';

const _sections = [
  {
    title: 'Instant Email Assignment',
    subtitle:
      'No extra steps—get your email address as soon as you have a phone number capable of receiving SMS messages.',
  },
  {
    title: 'Receive SMS messages Via Email as well',
    subtitle:
      'With a subscription, you can receive your SMS OTPs (One-Time Passcodes) via Email when travelling internationally.',
  },
  {
    title: 'Easily Share your MailNumber Email',
    subtitle:
      'Your registered MailNumber phone number is already your email with the MailNum.com domain.',
  },
  {
    title: 'AI Spam and Malicious Email Filtering',
    subtitle: 'Automatically filters out junk and malicious emails.',
  },
];

const FeaturesSection = () => {
  return (
    <Wrapper
      id="features"
      internalClassName="flex flex-col items-center justify-between gap-8 text-center md:flex-row md:gap-20 md:text-left"
    >
      <div className="flex flex-1 flex-col gap-6 md:items-start">
        <p className="caption tag">Why Choose Mail Number?</p>
        <h1 className="responsive-heading-1">
          The Smarter Way to Stay In Touch 💡
        </h1>
        {_sections.map(({ title, subtitle }) => (
          <div
            key={title}
            className="flex w-full flex-col items-center gap-4 border-b-[1px] border-gray-300 pb-6 md:flex-row md:items-start md:gap-8"
          >
            <div className="flex items-center justify-center rounded-full bg-primary p-1 text-center text-white">
              <CheckIcon className="size-5" />
            </div>
            <div className="flex flex-col gap-3">
              <h4>{title}</h4>
              <p className="caption">{subtitle}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-1 justify-end">
        <ResponsiveImage baseName="why-choose-mailnum" />
      </div>
    </Wrapper>
  );
};

export default FeaturesSection;
