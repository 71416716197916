import 'core/styles/index.css';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './core/App';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SnackbarProvider
      maxSnack={6}
      autoHideDuration={3000}
      action={(key) => (
        <XMarkIcon onClick={() => closeSnackbar(key)} className="size-5" />
      )}
    >
      <App />
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
